export const Resume=({data})=>{
    return (
       <>
       <br/>
<article className="border border-primary p-3">

<div > 
 <header >
   <h2 className="text-warning text-decoration-underline">{data.fname} Resume</h2> 
 </header>
 <div className="">
     <img src={`https://my-port-web-back.onrender.com/assets/${data.resumeImgPath}`} alt={`${data.fname} CV`}/><br/>
         <a href={`https://my-port-web-back.onrender.com/assets/${data.resumePath}`}>
     <button className="btn btn-primary">Download</button>
        </a>
 </div>           

</div> 
</article>
       </> 
    )
}