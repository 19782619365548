import { IonIcon } from "@ionic/react";
import { bookOutline } from "ionicons/icons";
import { useState,useEffect } from "react";

const Academics=()=>{
    const [academy,setAcademy]=useState({});
    const getAca=async()=>{
      const response=await fetch("https://my-port-web-back.onrender.com/users/academy",{
      method:"GET"
      });
        const academy1=await response.json();
        setAcademy(academy1);
  };
    useEffect(()=>{
    getAca();
    },[]);
    return (
      <>
      <br/>
    <article className="border border-primary p-3">
    <section className="">

  <div className="d-flex">
    <div className="">
      <IonIcon className='border border-warning m-1 p-1' icon={bookOutline}></IonIcon>
    </div>
    <h4 className="text-warning text-decoration-underline"> Education</h4>
  </div>
    { academy.education && 
    <div className="">
         <p className="h4 m-2">{academy.education[0]}</p>
         <p className="m-2">{academy.education[1]}</p>
         <p className="m-2">{academy.education[2]}</p>
         <p className="m-2"><b>Courses: </b>{academy.education[3]}</p>
    </div>}

</section>

<hr/>
<section className="">
<div className="">
   <h4 className="text-warning text-decoration-underline">Achievements</h4>
   <img alt="Coming soon..!" style={{color: "blue"}}/>
</div>

</section>
<hr/>

<section className="">
<div className="">
   <h3 className="text-warning text-decoration-underline">Extra Curricular Activities</h3>
</div >
<ol>
{
  academy.extra && Object.keys(academy.extra).map((ele)=>{
    return <li className="m-2 p-1"><b>{ele}</b>: <div>{academy.extra[ele]}</div></li>
  })
}
</ol>
</section>

</article> </>
 )
};

export default Academics;