import { useState,useEffect } from "react";

export const Internship=()=>{
  const [intern,setIntern]=useState();
  const getIntern=async()=>{
    const response=await fetch("https://my-port-web-back.onrender.com/users/intern",{
    method:"GET"
    });
      const intern1=await response.json();
      setIntern(intern1);
};
  useEffect(()=>{
  getIntern();
  },[]);

    return (
    <> <br/>
    <article className="border border-primary p-3">
        {
          intern && intern.map((ele)=>{ return <>
            <h4 className="text-warning text-decoration-underline">{ele.Title}</h4>
            <h6>{ele.Description}</h6>
            <ul>
              {ele.Concepts.map((el)=>{ return <li className="m-2 p-1">{el}</li>}) }
            </ul>
        <hr/> </>
      })}
    </article>
    </>
    )
}