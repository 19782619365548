import React from 'react';

import { IonIcon } from '@ionic/react';
import  {calendarOutline,locationOutline, logoDocker, logoGithub, 
  logoLinkedin, logoStackoverflow, mailOutline, phonePortraitOutline} from 'ionicons/icons';

const SideBar=({data,link})=>{
return(
<div className='fixed-left col-sm-3 col-md-3'>
<br/>
 <div className='border border-primary p-3'> 
<figure >
  <img alt={`${data.fname}`} style={{width:"70%",height:"50%",borderRadius:"15px",overflow:"hidden"}} 
  src={`https://my-port-web-back.onrender.com/assets/${data.picturePath}`}/>
</figure>
  <h1 className="text-warning text-decoration"><b>{data.fname} {data.lname}</b></h1>
  <p className="">{data.role}</p>
</div>
<br/>
<div className="border border-primary p-3">
<ul className="list-unstyled components ">
<li className="fs-5">
    <div className="d-flex">
   <IonIcon icon={mailOutline} className='border border-warning m-1 p-1'></IonIcon>
    <p className="text-warning">Email</p>
     </div>
    <a href={`mailto:${data.email}`} style={{fontSize:"1.5vw"}} className="text-dark lead">{data.email}</a>
</li><hr/>

<li className="fs-5">
    <div className="d-flex">
    <IonIcon className='border border-warning m-1 p-1' icon={phonePortraitOutline}></IonIcon>
    <p className="text-warning">Phone</p>
    </div>
    <a href={`tel:+91 ${data.phone}`} className="text-dark" style={{fontSize:"1.2vw"}}>+91 {data.phone}</a>
</li><hr/>

<li className="fs-5">
<div className="d-flex">
    <IonIcon className='border border-warning m-1 p-1' icon={calendarOutline}></IonIcon>
    <p className="text-warning">Birthday</p>
</div>
    <time>{data.dob}</time>
</li><hr/>

<li className="fs-5">
<div className="d-flex">
  <IonIcon className='border border-warning m-1 p-1' icon={locationOutline}></IonIcon>
    <p className="text-warning">Location</p>
</div>
    <div>{data.location}</div>
</li><hr/>
</ul>


<ul className="list-unstyled components list-inline">

<li className="list-inline-item fs-5">
  <a href={link.linkedin} className="icon-box">
    <IonIcon className='border border-warning m-1 p-1' icon={logoLinkedin}></IonIcon>
  </a>
</li>

<li className="list-inline-item fs-5">
  <a href={link.github} className="icon-box">
    <IonIcon className='border border-warning m-1 p-1' icon={logoGithub}></IonIcon>
  </a>
</li>

<li className="list-inline-item fs-5">
  <a href={link.kaggle} className="icon-box">
    <i className='border border-warning mb-5'>Kaggle</i>
  
  </a>
</li>

<li className="list-inline-item fs-5">
  <a href={link.docker} className=" icon-box">
    <IonIcon className='border border-warning m-1 p-1' icon={logoDocker}></IonIcon>
  </a>
</li>

<li className="list-inline-item fs-5">
  <a href={link.stack} className=" icon-box">
    <IonIcon className='border border-warning m-1 p-1' icon={logoStackoverflow}></IonIcon>
  </a>
</li>

</ul>
</div>

</div>



)
}

export default SideBar;