import { useState,useEffect } from "react";

export const Certificates=()=>{
  const [certi,setCerti]=useState();
  const getCerti=async()=>{
    const response=await fetch("https://my-port-web-back.onrender.com/users/certi",{
    method:"GET"
    });
      const certi1=await response.json();
      setCerti(certi1);
};
  useEffect(()=>{
  getCerti();
  },[]);
    return (
    <>
    <br/>
<article className="border border-primary p-3">
{
certi && <div><h4 className="text-warning text-decoration-underline">Professional Certificates</h4>
    <ul>
      { certi['Professional Certificates'].map((ele)=>{ return <li className="m-2 p-1">{ele}</li>}) }
    </ul><hr/>
     <h4 className="text-warning text-decoration-underline">Workshop/Symposium</h4>
   <ul>
    {certi['Workshops/Symposium'].map((ele)=>{ return <li className="m-2 p-1">{ele}</li>})}
   </ul>
</div>
}
</article>
    </>    
    )
};