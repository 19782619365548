import SideBar from './scenes/SideBar';
import NavBar from './scenes/NavBar';
import Main from './scenes/Main';
import { useEffect, useState } from 'react';
function App() {
  const [bar,setBar]=useState('0');
  const [data,setData]=useState([]);
  const [link,setLink]=useState({});
    
  const handleClick=(event)=>{
   let val=event.target.id;
   event.target.className="btn btn-link active";
    setBar(val);
  } ;

   useEffect(()=>{
     getUser();
  },[]);
 
    const getUser=async()=>{
       const response=await fetch("https://my-port-web-back.onrender.com/users",{
         method:"GET"
       });
       const data1=await response.json();
       setData(data1);
       setLink(data1.links)
    }
   
  return (
    <div className='container-fluid'>
      <nav className='navbar navbar-expand navbar-grey bg-dark fixed-top' style={{ zIndex: 1000,position:'relative' }}>
      <h1 className="navbar-brand text-warning text-decoration" style={{marginLeft:"20px",color:"blue",fontSize:"30px"}}>{data.fname} Port Web</h1>
       <NavBar handleClick={handleClick}/>
      </nav> 
      <div className='row'>
          <SideBar data={data} link={link}/>
        <div className='col-sm-9 col-md-9 '>
          <Main value={bar} data={data}/>
        </div>
      </div>
        
    </div>
  );
}

export default App;
