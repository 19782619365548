
const NavBar=({handleClick})=>{
  const arr=["Skills","Projects","Academics","Internship","Certificates","Resume"];
  return (
   <div className="collapse navbar-collapse justify-content-end" >
      <ul className="nav navbar-nav">
           {arr.map((ele,key)=>{
            return (<li key={ele} className="nav-item">
            <button id={key} className="btn btn-link btn-warning" onClick={(event)=>{handleClick(event);}}>
                 {ele}
             </button></li>)
           })}
      </ul>
  </div>
)};

export default NavBar;