import Academics from '../components/Academics';
import { Skills } from '../components/Skills';
import { Internship } from '../components/Internship';
import { Projects } from '../components/Projects';
import { Certificates } from '../components/Certificates';
import { Resume } from '../components/Resume';


const Main=({value,data})=>{
    
    
    if(value==='0') 
    return <Skills/>;

    else if(value==='1')
        return <Projects/>;
    
    else if(value==='2')
         return <Academics/>;

    else if(value==='3')
        return <Internship/>
    
    else if(value==='4')
        return <Certificates/>
    
    else
        return <Resume  data={data}/>

  
}

export default Main;