import { useState,useEffect } from "react";

export const Projects=()=>{

  const [pro,setPro]=useState([]);
  const getProjects=async()=>{
    const response=await fetch("https://my-port-web-back.onrender.com/users/project",{
    method:"GET"
    });
      const pro1=await response.json();
      setPro(pro1);
};
  useEffect(()=>{
  getProjects();
  },[]);
    return (   
      <><br/>
<article className="border border-primary p-3" data-page="projects">
    {pro.project && pro.project.map((ele)=>{return <div>
      <h4 className="text-warning text-decoration-underline">{ele.Title}</h4>
      <span>{ele.Description}</span>
      {ele.Frameworks && <span><b>Frameworks: </b>{ele.Frameworks}</span>}
      {ele.Packages && <span><b>Packages: </b>{ele.Packages}</span>}
      {ele.Datasets && <span><b>Datasets: </b>{ele.Datasets}</span>}
      {ele.Technologies && <span><b>Technologies: </b>{ele.Technologies}</span>}
      {ele.Languages && <span><b>Languages: </b>{ele.Languages}</span>}
      {ele.Tools && <span><b>Tools: </b>{ele.Tools}</span>} 
      {ele.Link && <a className="btn btn-primary" href={ele.Link}>Web demo</a>}
      <hr/>
    </div>
  })}
  </article>
</>        


    )
};