import { useEffect,useState } from "react";

export const Skills=()=>{
  const [skills,setSkills]=useState({});
  const [longitud,setLong]=useState(10.1);
  const [latitud,setLati]=useState(12.23);

  const getSkills=async()=>{
    if('geolocation' in navigator){
      navigator.geolocation.getCurrentPosition((pos)=>{
        const lati=pos.coords.latitude;
        const long=pos.coords.longitude;
        setLati(lati);
        setLong(long);
      })
    };
    const response=await fetch("https://my-port-web-back.onrender.com/users/skill",{
    method:"POST",
    headers:{'Content-Type':'application/json',},
    body:JSON.stringify({"lati":latitud,"long":longitud})
    });
      const skill1=await response.json();
      setSkills(skill1);
};
  useEffect(()=>{
  getSkills();
  },[]);
    return (
      <>
      <br/>
        <article  className="border border-primary p-3" data-page="">
        <header>
          <h4 className="text-warning text-decoration-underline">Technical Skills</h4>
        </header>
       
        <section className="">
          <ul className="">
        { skills.Technical && Object.keys(skills.Technical).map((k) =><li className="m-2 p-1"><b>{k}</b>: {skills.Technical[k]}</li>)}
          </ul>
        </section>
       
        <header>
          <h4 className="text-warning text-decoration-underline">Core Skills</h4>
        </header>
       
        <section className="">
          <ul className="">
          { skills.Core && Object.keys(skills.Core).map((k) =><li className="m-2 p-1"><b>{k}</b>: {skills.Core[k]}</li>)}
          </ul>
        </section>
       
        <header>
          <h4 className="text-warning text-decoration-underline">Platforms</h4>
        </header>
       
        <section className="">
          <ul className="">
           {skills.Platform && skills.Platform.map((element) => <li className="m-2 p-1">{element}</li>) }
          </ul>
        </section>
       
       
       </article>
      </>
    )
};

